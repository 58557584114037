// app/components/TawkMessenger.js
'use client';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function TawkMessenger() {
  return (
    <TawkMessengerReact
      propertyId="6745b2cc2480f5b4f5a442f6"
      widgetId="1idk44sia"
    />
  );
}
